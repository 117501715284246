import { createRouter, createWebHistory } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout'

import { isLoggedIn } from '@/util/auth'


const routes = [
  {
    path: '/',
    name: 'home',
    component: DefaultLayout,
    redirect: '/edicoes',
    children: [
      // {
      //   path: '/home',
      //   name: 'Home',
      //   component: () => import('@/views/HomeView.vue'),
      // },
      {
        path: '/edicoes',
        name: 'Edições',
        component: () => import('@/views/ProvasView.vue'),
      },
      {
        path: '/edicoes/:id',
        props: true,
        name: 'Detalhes da Prova',
        component: () => import('@/views/ProvaView.vue'),
      },
      {
        path: '/edicoes/:id/certificado',
        props: true,
        name: 'Certificado da Prova',
        component: () => import('@/views/CertificadoView.vue'),
      },
      {
        path: '/edicoes/cuco-24/certificado',
        props: true,
        name: 'Certificado da Prova 2024',
        component: () => import('@/views/Certificado2024View.vue'),
      },
      {
        path: '/edicoes/:id/resultado',
        props: true,
        name: 'Resultado da Prova',
        component: () => import('@/views/ResultadoView.vue'),
      },
      {
        path: '/edicoes/cuco-24/resultado',
        props: true,
        name: 'Resultado da Prova 2024',
        component: () => import('@/views/Resultado2024View.vue'),
      },
      {
        path: '/perfil',
        name: 'Perfil',
        component: () => import('@/views/PerfilView.vue'),
      },
    ],
  },
  {
    path: '/edicoes/:id/inscricao',
    props: true,
    name: 'Inscricao Cuco',
    component: () => import('@/layouts/InscricaoLayout.vue'),
    redirect: '/edicoes',
    children: [
      {
        path: '/edicoes/:id/inscricao/dados',
        props: true,
        name: 'Etapa 1',
        component: () => import('@/views/inscricao-cuco/DadosView.vue'),
      },
      {
        path: '/edicoes/:id/inscricao/socioeconomico',
        props: true,
        name: 'Etapa 2',
        component: () => import('@/views/inscricao-cuco/SocioeconomicoView.vue'),
      },
      {
        path: '/edicoes/:id/inscricao/confirmacao',
        props: true,
        name: 'Etapa 3',
        component: () => import('@/views/inscricao-cuco/ConfirmacaoView.vue'),
      },
      {
        path: '/edicoes/:id/inscricao/finalizado',
        props: true,
        name: 'Etapa 4',
        component: () => import('@/views/inscricao-cuco/FinalizadoView.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/LoginView.vue'),
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/esqueci-a-senha',
    name: 'Esqueci a senha',
    component: () => import('@/views/EsqueciSenhaView.vue'),
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/redefinir-senha/:token',
    props: true,
    name: 'Redefinir senha',
    component: () => import('@/views/RedefinirSenhaView.vue'),
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/confirmacao/:token',
    props: true,
    name: 'Confirmação',
    component: () => import('@/views/ConfirmacaoView.vue'),
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/ativacao',
    name: 'Reenviar E-mail',
    component: () => import('@/views/ReenviarEmailView.vue'),
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/certificado',
    name: 'Validar Certificado',
    component: () => import('@/views/ValidacaoCertificadoView.vue'),
    meta: {
      geral: true,
    },
  },
  {
    path: '/termos-de-uso-e-politica-privacidade',
    name: 'Termos de Uso e Política de Privacidade',
    component: () => import('@/views/TermosView.vue'),
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/manutencao',
    name: 'Manutencao',
    component: () => import("@/views/ManutencaoView.vue"),
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("@/views/Page404View.vue"),
    meta: {
      allowAnonymous: true,
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.meta.allowAnonymous && isLoggedIn() && !to.meta.geral) {
    next({ path: '/' })
  } else if (!to.meta.allowAnonymous && !isLoggedIn() && !to.meta.geral) {
    next({
      path: '/login',
      query: { redirect: to.fullPath },
    })
  } else {
    next()
  }
})

export default router
